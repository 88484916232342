<template>
  <!-- 添加商品弹窗 -->
  <div>
    <div class="dialogSelect">
      <el-input style="width: 25%; margin: 0 5px" v-model="search" placeholder="请输入你要查询的内容"></el-input>
      <el-button type="success" icon="el-icon-search" @click="handleQuery">查询</el-button>
      <el-button type="primary" @click="handleImport">导入</el-button>
    </div>
    <div style="padding: 20px; margin-top: 10px">
      <div class="file_form">

        <el-col :span="4">
          <List :classList="classList" @allFile="allFile" @labelClick="labelClick" />
        </el-col>
        <div style="padding: 10px 0; width: 100%">
          <el-table ref="multipleTable" :border="true" :data="tableData1" tooltip-effect="dark"
            style="width: 100%; border: 1px solid #e5e5e5" @selection-change="handleSelectionChange" @select="tabelChenge"
            @select-all="tabelAllChenge" @cell-dblclick="productClick">
            <el-table-column type="selection" align="center"> </el-table-column>
            <el-table-column prop="id" sortable label="编号" align="center">
            </el-table-column>
            <el-table-column prop="product_name" label="名称" align="center">
            </el-table-column>
            <el-table-column prop="unit" label="单位" align="center">
            </el-table-column>
            <el-table-column prop="market_price" sortable label="市场价" align="center">
            </el-table-column>
            <el-table-column prop="sales_price" sortable label="销售价" align="center">
            </el-table-column>
          </el-table>
          <div class="tableBottom">
            <span style="">显示第&nbsp;{{
              tableData1.length == 0 ? 0 : (page - 1) * length + 1
            }}&nbsp;至&nbsp;{{
  (page - 1) * length + tableData1.length
}}&nbsp;项结果，共&nbsp;{{ total }}&nbsp;项</span>

            <div style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              ">
              <el-button @click="homePage">首页</el-button>
              <el-pagination background layout="prev,pager,next" :total="this.total" :page-size="6"
                @current-change="currentChange" @prev-click="backPage" @next-click="nextPage"
                :current-page="page"></el-pagination>
              <el-button @click="endPage">末页</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import qs from "qs";
import { listProduct } from "@/api/salesManagement/manualDeclaration.js";
import List from "@/components/main/list";
export default {
  props: ["show", "importConfig"],
  components: { List },
  data() {
    return {
      search: "",
      commercial_id: localStorage.getItem("commercial_id") ||
        sessionStorage.getItem("commercial_id"),
      cid: 0,
      tableData1: [],
      isFileShow: false,
      total: 0,
      page: 1,
      length: 6,
      filStorages: ["到店自提", "自提水票", "配送专区"],
      addList: [],
      isLabel: "",
      // commercial_id: "",
      isShow: this.productShow,
      classList: [],
    };
  },
  // 不直接修改props中的属性，而是通过watch监听属性变化
  watch: {
    productShow(val) {
      this.isShow = val;
    },
  },
  async created() {

  },
  mounted() {
    this.getClassList();
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.productAdd();
  },
  computed: {},
  methods: {
    handleQuery() {
      this.$http
        .post(
          "/product/search_1",
          ({
            commercial_id: this.commercial_id,
            search: this.search,
            currentPage: 1,
            currentLength: 6
          })
        )
        .then((res) => {
          this.tableData1 = res.data.data;
          this.dialogVisible = true;
          this.total = res.data.count;
        });
    },
    allFile() {
      if (this.cid == 0) return;
      this.cid = 0;
      this.inquireList();
    },
    inquireList() {
      this.$http
        .post("/product/lst", {
          // product_name: this.search,
          currentPage: this.page,
          currentLength: this.length,
          cid: this.cid,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.tableData1 = res.data.data;
          this.dialogVisible = true;
          this.total = res.data.count;
        });
    },
    labelClick(id) {
      this.cid = id;
      this.inquireList();
    },
    getClassList() {
      this.$http
        .post("/Goods_Category/lst", {
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.classList = res.data.data;
        });
    },
    // 关闭弹窗回调
    handleClose() {
      // this.$confirm("确认关闭？")
      //   .then(() => {
      this.addList = [];
      this.$refs.multipleTable.clearSelection();

      this.$emit("handleClose");
      // })
      // .catch(() => {});
    },
    //获取商品列表
    productAdd() {
      // console.log(this.page, this.length, this.commercial_id, this.searchVal)
      listProduct(this.page, this.length, this.commercial_id, this.searchVal)
        .then((res) => {
          this.tableData1 = res.data.data;
          this.total = res.data.count;
          // console.log(res);
        });
    },
    // 搜索商品
    serachProduct() {
      this.page = 1;
      this.productAdd();
    },
    // 商品双击
    productClick(row) {
      // this.$emit("productClick", row);
      // this.isShow = !this.isShow
      console.log('a');
    },

    //添加商品确定
    handleImport() {
      this.$emit("update:show", false);
      //   this.dialogVisible = false;
      this.$emit("importConfig", this.addList);
      // this.addList.forEach((item) => {
      //   this.$refs.multipleTable.toggleRowSelection(item);
      // });
    },
    fileClick(i) {
      this.isLabel = i;
      console.log("文件选中" + i);
    },
    //下一页
    nextPage() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length))
        this.page = ++this.page;
      this.productAdd();
    },
    //上一页
    backPage() {
      if (this.page == 1) return;
      this.page = --this.page;
      this.productAdd();
    },
    currentChange(e) {
      this.page = e;
      this.productAdd();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //商品状态改变
    tabelChenge(selection) {
      this.addList = selection;
    },
    //商品全选按钮发生改变
    tabelAllChenge(selection) {
      this.addList = selection;
    },

    homePage() {
      this.page = 1;
      this.productAdd();
    },
    endPage() {
      this.page = Math.ceil(this.total / this.length);
      this.productAdd();
    },
  },
};
</script>
<style lang="less" scoped>
.file_form {
  display: flex;
  justify-content: space-between;

  .file {
    padding: 20px;

    .file_level1 {
      display: flex;
      align-items: center;
      width: 120px;

      a {
        font-size: 14px;
        color: #333;
        font-family: "微软雅黑";
      }
    }

    .file_level2 {
      padding-left: 40px;
      margin-top: 3px;

      a {
        height: 20px;
        font-size: 14px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
