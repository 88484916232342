import { render, staticRenderFns } from "./goodsStock.vue?vue&type=template&id=0177ede3&scoped=true&"
import script from "./goodsStock.vue?vue&type=script&lang=js&"
export * from "./goodsStock.vue?vue&type=script&lang=js&"
import style0 from "./goodsStock.vue?vue&type=style&index=0&id=0177ede3&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0177ede3",
  null
  
)

export default component.exports