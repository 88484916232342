<template>
    <div>
        <el-dialog :title="title" :visible.sync="tabShow" width="60%" :before-close="editClose">
            <div class="row">
                <div class="title">日期范围：</div>
                <el-date-picker v-model="date1" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"
                    style="width: 150px">
                </el-date-picker>
                -
                <el-date-picker v-model="date2" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"
                    style="width: 150px">
                </el-date-picker>
                <el-button type="success" icon="el-icon-search" @click="searchClick">查询</el-button>
                <el-button type="success" icon="el-icon-delete" @click="clearClick">清除</el-button>
                <el-button type="primary">
                    <div class="button-box">
                        <span class="spriteChart_daochu" style="display: inline-block"></span>导出报表
                    </div>
                </el-button>
            </div>
            <div class="box">
                <el-table :data="tableData" border>
                    <el-table-column prop="create_time" label="日期"> </el-table-column>
                    <!-- <el-table-column prop="date" label="桶品牌"> </el-table-column> -->
                    <el-table-column prop="operator" label="操作人"> </el-table-column>
                    <el-table-column prop="remake" label="备注"> </el-table-column>
                    <el-table-column prop="order_id" label="订单编号"> </el-table-column>
                    <el-table-column prop="previous_inventory" label="上期库存">
                    </el-table-column>
                    <el-table-column prop="chage_value" label="变化值"> </el-table-column>
                    <el-table-column prop="current_inventory" label="本期库存"> </el-table-column>
                </el-table>
            </div>
            <div class="tableBottom">
                <span>显示第&nbsp;{{
                    tableData.length == 0 ? 0 : (page - 1) * length + 1
                }}&nbsp;至&nbsp;{{
    (page - 1) * 10 + tableData.length
}}&nbsp;项结果，共&nbsp;{{ tabletotalNum }}&nbsp;项</span>
                <div style="display: flex; justify-content: flex-end; align-items: center">
                    <el-pagination background layout="prev, pager, next" :total="tabletotalNum" :current-page="page"
                        @current-change="currentChange">
                    </el-pagination>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: ["title", "tabShow", "url", "rowId"],
    data() {
        return {
            date1: "",
            date2: "",
            page: 1,
            length: 10,
            tableData: [],
            tabletotalNum: 0,
        };
    },
    mounted() {
        // if (this.$props.rowId) {
        //     this.getTabList();
        // }
        this.searchClick()
    },
    computed: {},
    methods: {
        // async getTabList() {
        //     const res = await this.$http.post(
        //         this.$props.url,
        //         this.$qs.stringify({
        //             id: this.$props.rowId,
        //             currentPage: this.page,
        //             currentLength: this.length,
        //         })
        //     );
        //     this.tableData = res.data.data;
        //     this.tabletotalNum = res.data.count;
        // },
        editClose() {
            this.$emit("tabClose");
        },
        currentChange(page) {
            this.page = page;
        },
        async searchClick() {
            const res = await this.$http.post(
                "/inventory/product_inventory/logList",
                {
                    product_id: this.$props.rowId,
                    firstTime: this.date1,
                    lastTime: this.date2,
                }
            );
            this.tableData = res.data.data;
            this.tabletotalNum = res.data.count;
        },
        clearClick() {
            this.date1 = "";
            this.date2 = "";
        },
    },
    watch: {
        page: {
            handler() {
                this.searchClick();
            },
        },
    },
};
</script>
<style lang="less" scoped>
.row {
    display: flex;
    align-items: center;
    padding: 0 20px;

    &-num {
        width: 370px;
    }

    span {
        margin-right: 10px;
        cursor: pointer;
    }
}

.box {
    padding: 0 20px;
}
</style>
  