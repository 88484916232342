<template>
  <div>
    <div style="width:130%">
        <search-case :searchData="searchData"></search-case>
    </div>

    <div class="file_form">
      <el-row>
        <el-col :span="4">
          <!-- 文件夹处 -->
          <div class="file">
            <div class="file_level1" @click="isFileShow = !isFileShow">
              <img
                v-if="isFileShow"
                style="width: 10px; height: 10px"
                :src="require('@/assets/mianAssets/文件夹减.png')"
                alt=""
              />
              <img
                v-if="!isFileShow"
                style="width: 10px; height: 10px"
                :src="require('@/assets/mianAssets/文件夹加.png')"
                alt=""
              />
              <img
                style="width: 22px; height: 22px; margin-left: 5px"
                :src="require('@/assets/mianAssets/文件夹.png')"
                alt=""
              />
              <a href="#" @click="isLabel = -1">全部文件</a>
            </div>
            <div class="file_level2" v-if="isFileShow">
              <ul>
                <li
                  v-for="(file, index) in filStorages"
                  :key="file"
                  style="display: flex; align-items: center"
                  @click="fileClick(index)"
                >
                  <img
                    style="width: 12px; margin-right: 3px"
                    :src="require('@/assets/mianAssets/空白页.png')"
                    alt=""
                  /><a href="#" :title="file"
                    ><span :class="isLabel == index ? 'label' : ''">{{
                      file
                    }}</span></a
                  >
                </li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <!-- 表格 -->
          <div style="padding: 10px 0">
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              border
              @selection-change="handleSelectionChange"
              style="width: 100%"
            >
              <el-table-column type="selection" width="44"> </el-table-column>
              <el-table-column prop="pic" sortable label="图片"></el-table-column>
              <el-table-column prop="serial" sortable label="编号"></el-table-column>
              <el-table-column prop="name" sortable label="名称"></el-table-column>
              <el-table-column prop="unit" sortable label="单位"></el-table-column>
              <el-table-column prop="classify" sortable label="分类"></el-table-column>
              <el-table-column prop="stock" sortable label="期初库存"></el-table-column>
              <el-table-column prop="putInStorage" sortable label="入库"></el-table-column>
              <el-table-column prop="putOutStorage" sortable label="出库"></el-table-column>
              <el-table-column prop="balance" sortable label="结存"></el-table-column>
            </el-table>
            <div class="tableBottom">
              <span v-if="isResult" style=""
                >显示第&nbsp;1&nbsp;至&nbsp;{{
                  tableFinshNum
                }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
              >
              <span v-if="!isResult" style=""
                >显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
                  tableFinshNum
                }}&nbsp;项</span
              >
              <div
                style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <el-button>首页</el-button>
                <el-button>上页</el-button>
                <el-pagination
                  background
                  layout=" pager,slot"
                  :total="1"
                ></el-pagination>
                <el-button>下页</el-button>
                <el-button>末页</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchData: {
        date: "",
        selectSpan1: "仓库店铺:",
        option1: "",
        selectPlace1: "请选择",
        optiones1: [],
        QueryContent: "",
        searchPlace: "输入要查询的内容",
        searchBtn1: true,
      },

      isFileShow: true,
      filStorages: ["到店自提", "自提水票", "配送专区"],
      isLabel: -1,

      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleEdit() {},
    handleDelete() {},

    fileClick(i) {
      this.isLabel = i;
      console.log("文件选中" + i);
    },
  },
};
</script>
<style scoped lang="less">
.file_form {
  .file {
    padding: 20px;

    .file_level1 {
      display: flex;
      align-items: center;
      width: 120px;

      a {
        font-size: 14px;
        color: #333;
        font-family: "微软雅黑";
      }
    }

    .file_level2 {
      padding-left: 40px;
      margin-top: 3px;

      a {
        height: 20px;
        font-size: 14px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}
.label {
  background-color: #ffeabf;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
/deep/ .cell {
  padding: 0 !important;
}
</style>
