<template>
  <div>
    <bread-crumb :crumbData="['商品管理']" :chooseCrumbData="2" @close="hide"></bread-crumb>
    <!-- <search-case :searchData="searchData"></search-case> -->

    <div style="padding: 20px 20px 0; white-space: nowrap">
      <el-row>
        <el-col :span="6" style="margin:0px 10px">
          <span>仓库店铺： </span>
          <el-select v-model="query.commercial_id" clearable placeholder="类型">
            <el-option v-for="item in enterprise_name_list" :key="item.value" :value="item.value" :label="item.label">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="6">
          <el-input v-model="query.search" placeholder="输入要查询的编号" style="width: 70%"></el-input>
          <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery()">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin-left: 3px">查询</span>
            </div>
          </el-button>
        </el-col>
      </el-row>
    </div>


    <div style="
        padding: 10px 20px;
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
      ">
      <div style="margin-right: 5px">
        <el-button type="primary" size="mini" @click="dialogVisible1 = true">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 3px">导入商品</span>
          </div>
        </el-button>
      </div>
      <div style="margin-right: 5px">
        <el-button type="danger" size="mini">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 3px">批量删除</span>
          </div>
        </el-button>
      </div>
      <div style="margin-right: 5px">
        <el-button type="primary" size="mini" @click="dialogVisible2 = true">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 3px">账面结存</span>
          </div>
        </el-button>
      </div>
      <div style="margin-right: 5px">
        <el-button type="primary" size="mini" @click="dialogVisible3 = true">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 3px">库存盘点</span>
          </div>
        </el-button>
      </div>
    </div>

    <div class="file_form">
      <el-row>
        <el-col :span="3">
          <!-- 文件夹处 -->
          <List :classList="classList" @allFile="allFile" @labelClick="labelClick" />
        </el-col>
        <el-col :span="21">
          <!-- 表格 -->
          <div style="padding: 10px 0">
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border
              @selection-change="handleSelectionChange" style="width: 100%">
              <el-table-column type="selection" width="44"> </el-table-column>
              <el-table-column prop="id" sortable label="ID"></el-table-column>
              <el-table-column label="图片" width="180">
                <template slot-scope="scope">
                  <img :src="'http://192.168.3.3' + scope.row.thumbnail" width="75px" height="75px" />
                </template>
              </el-table-column>
              <!-- <el-table-column prop="thumbnail" label="商品资料库"></el-table-column> -->
              <el-table-column prop="create_time" sortable label="创建日期"></el-table-column>
              <el-table-column prop="number" sortable label="编号"></el-table-column>
              <el-table-column prop="product_name" label="名称"></el-table-column>
              <el-table-column prop="unit" label="单位"></el-table-column>
              <el-table-column prop="classify_name" label="分类"></el-table-column>
              <el-table-column prop="inventory" label="库存"></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
                  <span style="margin: 0 10px"></span>
                  <i class="el-icon-search poiner" @click="handleDelete(scope.row)"></i>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div class="tableBottom">
              <span v-if="isResult" style="">显示第&nbsp;1&nbsp;至&nbsp;{{
                tableFinshNum
              }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>
              <span v-if="!isResult" style="">显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
                tableFinshNum
              }}&nbsp;项</span>
              <div style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                ">
                <el-button>首页</el-button>
                <el-button>上页</el-button>
                <el-pagination background layout=" pager,slot" :total="1"></el-pagination>
                <el-button>下页</el-button>
                <el-button>末页</el-button>
              </div>
            </div> -->
            <pagination :handleQuery="handleQuery" :currentPage="query.currentPage" :currentLength="query.currentLength"
              :total="total">
            </pagination>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-dialog title="商品资料库" :visible.sync="dialogVisible1" width="70%" :before-close="handleClose">
      <add-goods :show.sync="dialogVisible1" @importConfig="importConfig"></add-goods>
    </el-dialog>

    <el-dialog title="账面结存" :visible.sync="dialogVisible2" width="70%" :before-close="handleClose">
      <group-book></group-book>
    </el-dialog>

    <el-dialog title="库存盘点" :visible.sync="dialogVisible3" width="70%" :before-close="handleClose">
      <stock-taking></stock-taking>
    </el-dialog>
    <EditDialog :edit-show="editShow" title="出入库" @editClose="editClose" :data="editData" v-if="editShow" />
    <TabDialog title="库存日志" :tabShow="tabShow" @tabClose="tabClose" :rowId="rowId"
      url="/inventory/empty_barrel/barrel_lst" v-if="tabShow" />
  </div>
</template>
<script>
import pagination from "@/components/pagination/index.vue";
import addGoods from "./childrens/addGoods.vue";
import groupBook from "./childrens/bookBalance.vue";
import stockTaking from "./childrens/stockTaking.vue";
import List from "@/components/main/list";

import EditDialog from "./childrens/edit.vue";
import TabDialog from "./childrens/tab.vue";

export default {
  components: {
    addGoods,
    groupBook,
    stockTaking,
    List,
    pagination,
    EditDialog,
    TabDialog
  },
  props: ['close'],

  data() {
    return {
      total: 0,
      commercial_id: localStorage.getItem("commercial_id") ||
        sessionStorage.getItem("commercial_id"),
      enterprise_name_list: [],
      query: {
        commercial_id: (localStorage.getItem("commercial_id") ||
          sessionStorage.getItem("commercial_id")) * 1,
        cid: 0,
        search: null,
        currentPage: 1,
        currentLength: 10,
      },
      hide: this.close || this.onClose,
      searchData: {
        date: "",
        selectSpan1: "仓库店铺:",
        option1: "",
        selectPlace1: "请选择",
        optiones1: [],
        QueryContent: "",
        searchPlace: "输入要查询的内容",
        searchBtn1: true,
      },

      isFileShow: true,
      filStorages: ["到店自提", "自提水票", "配送专区"],
      isLabel: -1,

      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,

      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      classList: [],
      editShow: false,
      editData: "",
      tabShow: false,
      rowId: "",
      show: false,
    };
  },
  async mounted() {
    this.enterprise_name_list = (await this.$http.post("/Client/commercial_owner/lst")).data.data.map(el => {
      return {
        label: el.enterprise_name,
        value: el.id
      }
    });
    this.handleQuery()
    this.getClassList();
  },
  computed: {},
  methods: {
    async importConfig(arr) {
      // console.log(arr);
      arr = arr.map(el => {
        return {
          thumbnail: el.thumbnail,
          number: el.number,
          product_id: el.id,
          category_id: el.cid,
          unit: el.unit,
          commercial_id: this.commercial_id
        }
      });
      // console.log(arr);
      let res = (await this.$http.post("/Inventory/product_inventory/save", arr)).data;
      if (res.status == "success") {
        this.$message.success("导入成功！")
      } else {
        this.$message.error("导入失败！")
      }
    },
    async handleQuery(page = 1) {
      this.query.currentPage = page;
      let res = (await this.$http.post("/Inventory/product_inventory/lst", this.query)).data;
      this.total = res.count;
      this.tableData = res.data;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onClose() {

    },
    handleEdit(row) {
      this.editData = row;
      row.name = row.product_name
      this.editShow = true;
    },
    async handleDelete(row) {
      this.rowId = row.product_id;

      this.tabShow = true;
    },
    labelClick(id) {
      // console.log(id);
      this.query.cid = id;
      this.handleQuery();
    },
    allFile() {
      console.log("all");
    },
    getClassList() {
      this.$http
        .post("/Goods_Category/lst", {
          commercial_id: localStorage.getItem("commercial_id") ||
            sessionStorage.getItem("commercial_id"),
        })
        .then((res) => {
          this.classList = res.data.data;
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => { });
    },
    editClose() {
      this.editShow = false;
      this.handleQuery();
    },
    tabClose() {
      this.tabShow = false;
    },
  },
};
</script>
<style scoped lang="less">
.label {
  background-color: #ffeabf;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .cell {
  padding: 0 !important;
}

/deep/ .el-dialog__header {
  padding: 5px !important;
}

/deep/ .el-dialog__title {
  font-size: 16px !important;
}

/deep/ .el-dialog__body {
  padding: 10px !important;
}
</style>
